import React  from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import ContactMe from '../components/contactMe';

const IndexPage = () => {

  return (
    <Layout>
      <Head title="Home"/>
      <div className="mb-6 flex text-3xl font-bold">
        <h1 className="pr-6">Hello</h1>
        <span
          role="img"
          aria-label="waving hand emoji"
        >&#128075;
        </span>
      </div>
      <p className="mb-6">
        I'm Jared, a software engineer living in the greater New York City area. I built this site using Gatsby, React, GraphQL, and TailwindCSS.
      </p>
      <ContactMe />
    </Layout>
  )
}

export default IndexPage;
