import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import resume from '../assets/jared-kaneshiro_resume.pdf';

const ContactMe = () => {

  return (
    <p className="mb-6">
      Need help with your project? <a
        href={resume}
        target="_blank"
        rel="noreferrer"
        className="text-teal-500 hover:text-teal-800"
      >View my resume <FontAwesomeIcon icon={faExternalLinkAlt} alt="" /></a>, connect 
      with me via email me at <a
        href="mailto:jmkaneshiro@gmail.com"
        target="_blank"
        rel="noreferrer"
        className="text-teal-500 hover:text-teal-800 underline"
      >jmkaneshiro@gmail.com</a>, on <a 
        href="https://www.linkedin.com/in/jared-kaneshiro-9b83322b/"
        target="_blank"
        rel="noreferrer"
        className="text-teal-500 hover:text-teal-800">LinkedIn</a> or 
        any of the <Link to="/contact" className="text-teal-500 hover:text-teal-800 underline">other social networks</Link> where you can find me.
    </p>
  )
};

export default ContactMe;